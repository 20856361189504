import React, { useState } from 'react';
import Image from '@/common/image';
import Slider from 'react-slick';
import { HomePageOfficeTour } from '../../../../constants';
import { ReactComponent as Right } from '../../../../images/svgs/right.svg';
import { ReactComponent as Left } from '../../../../images/svgs/left.svg';

interface leftArrowProps {
    onClick?: () => void;
}
interface rightArrowProps {
    onClick?: () => void;
}

const OfficeTourCarousel = () => {
    const [currentSlide, setCurrentSlide] = useState({ next: 0, current: 1 });

    const LeftArrow = ({ onClick }: leftArrowProps) => {
        return (
            <div
                onClick={() => onClick && onClick()}
                role="button"
                tabIndex={0}
                onKeyDown={() => onClick && onClick()}
                className="absolute  z-50 right-0 mr-[-6px] lg:mr-[-18px] bottom-[45%] cursor-pointer bg-lightbrown p-3 lg:p-5 rounded-md hidden sm:block"
            >
                <Right />
            </div>
        );
    };

    const RightArrow = ({ onClick }: rightArrowProps) => {
        return (
            <div
                onClick={() => onClick && onClick()}
                role="button"
                tabIndex={0}
                onKeyDown={() => onClick && onClick()}
                className="absolute z-50 ml-[-6px] lg:ml-[-18px]  bottom-[45%] cursor-pointer bg-lightbrown p-3 lg:p-5 rounded-md hidden sm:block"
            >
                <Left />
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <LeftArrow />,
        prevArrow: <RightArrow />,
        beforeChange: (current: number, next: number) => {
            setTimeout(() => {
                setCurrentSlide({ next, current });
            }, 400);
        },
        customPaging: (i: number) => {
            return (
                <div
                    className={`w-2 rounded-full cursor-pointer ${
                        i === currentSlide.next
                            ? 'px-[14px] py-[6.5px] -mt-8  bg-[#BE383C]'
                            : 'px-[9px] py-[5px] w-2 rounded-full bg-[#E4D9D9] mx-1  -mt-[30.5px] '
                    }`}
                />
            );
        },
        responsive: [
            {
                breakpoint: 639,
                settings: {
                    centerPadding: '40px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                },
            },
        ],
    };

    const TourPics = ({ pic, index }: any) => (
        <div className={`my-20`}>
            <Image
                filename={pic}
                className={`cursor-pointer mx-2 rounded-2xl drop-shadow-lg
                ${currentSlide.next + 2 == index && 'sm:prev-slide'}
                ${currentSlide.next == index && 'sm:next-slide'}
                ${
                    currentSlide.next + 1 == index &&
                    currentSlide.current > currentSlide.next &&
                    'sm:prev-reverse'
                }
                ${
                    currentSlide.next + 1 == index &&
                    currentSlide.current < currentSlide.next &&
                    'sm:next-reverse'
                }
            `}
                alt={`office-tour${index}`}
            />
        </div>
    );

    return (
        <Slider {...settings}>
            {HomePageOfficeTour.map((item, index) => (
                <TourPics pic={item.pic} index={index} key={index} />
            ))}
        </Slider>
    );
};
export default OfficeTourCarousel;
