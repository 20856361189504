import Home from '@/home';
import { SEO } from '@/common/seo';

const HomePage: Page = () => <Home />;

export default HomePage;

export const Head = () => (
    <SEO
        title="Cosmetic Dentist in Phoenix, Arizona - Zapata Dental"
        description="Zapata Dental Professionals are committed to providing best dental treatment to children, teens, and adults in Phoenix with an exceptional level of attention and care."
    />
);
