import Image from '@/common/image';
import Button from '@/common/button';

export default function Hero() {
    return (
        <div className="flex flex-col-reverse md:flex-row items-center">
            <div className="w-[100%] lg:w-[50%] relative">
                <Image
                    filename="heroBg.png"
                    className="h-[412px] md:h-[500px] lg:h-full"
                    alt="heroBg"
                />

                <div className="absolute w-full top-1/2 left-1/2 right-1/2 transform -translate-x-1/2  -translate-y-1/2">
                    <div className="w-[92%] lg:w-[85%] mx-auto">
                        <div className="HeroSmallHeading text-primary">We Are Here For You</div>
                        <div className="DH1MH1 max-w-[200px] 2xl:max-w-[583px] sm:max-w-[350px]">
                            Exceptional Level of Attention & Care
                        </div>
                        <div className="flex items-center pt-[25px]">
                            <div>
                                <Image
                                    filename="map-pin.png"
                                    className="sm:w-[40px] sm:h-[40px] w-[20px] h-[20px]"
                                    alt="map-pin"
                                />
                            </div>
                            <div className="pl-[20px] DH4MH2 max-w-[250px] 2xl:max-w-[512px] sm:max-w-[400px]">
                                <a
                                    href="https://www.google.com/maps/place/Zapata+Dental/@33.481919,-112.1869531,17z/data=!4m13!1m7!3m6!1s0x872b14e154cc9d65:0x989800051a748435!2s2915+N+59th+Ave,+Phoenix,+AZ+85033!3b1!8m2!3d33.481919!4d-112.1847591!3m4!1s0x872b14e15e2ba8d5:0x91dd548a60c5394b!8m2!3d33.481855!4d-112.1858112"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    2915 North 59th Avenue Phoenix, Arizona 85033
                                </a>
                            </div>
                        </div>
                        <div className="lg:flex block items-center mt-8 sm:mt-12">
                            <a href="#contact">
                                <Button
                                    arrow
                                    text="Get In Touch With Us"
                                    className="bg-primary text-white"
                                />
                            </a>
                            <div className="flex items-center mt-6 lg:mt-0">
                                <Image
                                    filename="green-phone.png"
                                    className="h-[13px] w-[13px]  lg:ml-[50px] xl:ml-[65px] mr-[10px]"
                                    alt="location"
                                />
                                <a href="tel:(623) 846 - 0575">
                                    <div className="text-primary">(623) 846 - 0575</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-[100%] lg:w-[50%]">
                <Image
                    filename="hero.png"
                    className="h-[340px] md:h-[500px] lg:h-full"
                    alt="Costmetic1"
                />
            </div>
        </div>
    );
}
