import React from 'react';
import ImageDescriptionRow from '@/common/image-description-row';
import Hero from '@/home/components/hero';
import ContactForm from '@/common/contact-form';
import Testimonials from '@/home/components/testimonial-carousel';
import OfficeTour from '@/home/components/office-tour-carousel';
import Button from '@/common/button';

const Home = () => {
    return (
        <div>
            <Hero />
            <div className="px-[16px] lg:px-[70px] 3xl:px-[150px]  my-[120px] max-w-[1920px] mx-auto">
                <ImageDescriptionRow
                    title="Offering Cosmetic Dentistry"
                    description="Zapata Dental is committed to providing each and every patient with an exceptional level of attention and care. You can trust that you will be receiving quality dental care from a well-educated team as we are proudly serving the Phoenix area for over 10 years. Our dedicated staff works with a dentist as a team to provide high-quality comprehensive dental care. Our knowledgeable dentists take pride in paying very close attention to every patient's needs."
                    className="xl:flex-row-reverse mb-[120px]"
                    buttonText="(623) 846 - 0575)"
                    imgSrc="Comsestic Dentistry.jpg"
                    leftRedShadow
                    phone
                    rowReverse
                />
                <ImageDescriptionRow
                    title="Dr Rick Zapata"
                    description="Dr. Zapata firmly believes that superior quality and patient satisfaction are the fundamentals of excellence in dentistry. He takes pride in maintaining a personable atmosphere with a friendly vibrant staff. Dr. Zapata is dedicated to providing personalized care to all of his patients by at the forefront of dental techniques and using state of the art equipment. He consistently strives to create beautiful smiles and has a unique ability to connect with his patients on a personal level."
                    className="xl:flex-row mb-[120px]"
                    buttonText="Meet the Team"
                    buttonLink="/about"
                    rightRedShadow
                    imgSrc="dr-rick-home.jpg"
                />
                <div className="my-28 overflow-visible">
                    <div className="-mb-10">
                        <div className="DH2MH3 text-primary text-center leading-none">
                            Our Office Tour
                        </div>
                        <div className="DP1MP1 text-blackT text-center max-w-[1110px] my-8 mx-auto">
                            Lets have a look at our dental office located in 2915 North 59th Avenue
                            Phoenix, Arizona 85033 where you could visit 5 days of the week after
                            getting your appointment through a phone call with one of our
                            representatives
                        </div>
                        <a href="tel:+6238460575">
                            <Button
                                className="text-white mx-auto bg-primary mb-8 md:mb-6"
                                text="(623) 846 - 0575"
                                phone
                            />
                        </a>
                    </div>
                    <OfficeTour />
                </div>
                <Testimonials />
                <div className="mt-16">
                    <ContactForm />
                </div>
            </div>
        </div>
    );
};

export default Home;
