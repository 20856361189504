import React, { useState } from 'react';
import Slider from 'react-slick';
import Image from '@/common/image';
import { testimonials } from '../../../../constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './testimonial-carousel.css';

interface leftArrowProps {
    onClick?: () => void;
}
interface rightArrowProps {
    onClick?: () => void;
}

const Testimonials = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const LeftArrow = ({ onClick }: leftArrowProps) => {
        return (
            <div
                onClick={onClick}
                role="button"
                tabIndex={0}
                onKeyDown={() => onClick && onClick()}
                className="absolute z-50 right-6 md:right-10 bottom-0 cursor-pointer"
            >
                <Image filename="arr-right.png" alt="" className="w-[20px]" />
            </div>
        );
    };

    const RightArrow = ({ onClick }: rightArrowProps) => {
        return (
            <div
                onClick={() => onClick && onClick()}
                role="button"
                tabIndex={0}
                onKeyDown={() => onClick && onClick()}
                className="absolute z-50 right-90 md:right-[81px] bottom-0 cursor-pointer ml-5 md:ml-0"
            >
                <Image filename="arr-left.png" alt="" className="w-[20px]" />
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <LeftArrow />,
        prevArrow: <RightArrow />,
        afterChange: (current: number) => setCurrentSlide(current),
        customPaging: (i: number) => {
            return (
                <span
                    className={
                        i === currentSlide
                            ? 'px-[14px] py-[6.5px] w-2 rounded-full bg-[#BE383C] active hidden md:block cursor-pointer'
                            : 'px-[9px] py-[5px] w-2 rounded-full bg-[#E4D9D9] mx-1 mt-[1px] inactive hidden md:block cursor-pointer'
                    }
                />
            );
        },
        dotsClass: 'testimonial-pagination',
    };

    return (
        <>
            <div className="text-center md:text-start md:flex justify-center items-center flex-wrap md:flex-nowrap">
                <h2 className="DH2MH3 text-[#078770] block md:hidden mb-5">Testimonials</h2>
                <div className="flex md:block justify-center items-center w-[100%] md:w-[30%]">
                    <Image
                        filename="testimonials.png"
                        alt="testimonials"
                        className="min-w-[300px] md:min-w-[200px] lg:min-w-[300px] 2xl:min-w-[400px] md:w-full"
                    />
                </div>
                <div className="ml-0 md:ml-10 lg:ml-20 w-[100%] md:w-[70%]">
                    <p className="DH2MH3 text-[#078770] hidden md:block mb-2">Testimonials</p>
                    <Slider {...settings}>
                        {testimonials.map((testimonial, i) => (
                            <div key={i} className="pb-10">
                                <p className="DP1MH3 text-[#2D3130] mt-4 mb-3 lg:mt-0">
                                    {testimonial.name}
                                </p>
                                <p className="DP2MP1 text-[#74777B] md:text-start text-center">
                                    {testimonial.description}
                                </p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default Testimonials;
